import React from 'react'

export const categories = [{
    id: 'telemedicine',
    name: 'Telemedicine'
}, {
    id: 'erectile-dysfunction',
    name: 'Erectile Dysfunction'
}, {
    id: 'order',
    name: 'Order'
},{
    id: 'shipping',
    name: 'Shipping'
}]

export const questions = [{
    id: 'telemedicine',
    content: [{
        id: 1,
        question: 'Can a doctor diagnose erectile dysfunction remotely?',
        answer: () => (
            <>
              <p>
                  Yes, erectile dysfunction can usually be diagnosed remotely. This process is known as telemedicine or telehealth
                  and involves a patient answering questions on their symptoms and medical history. A physician can then review these questions,
                  the same way they would during an in-person diagnosis.
              </p>
              <p>
                If the practitioner deems you to be a good candidate via telemedicine, they can then prescribe the appropriate treatment.
                It’s therefore important to answer each question truthfully and to the best of your knowledge. This will ensure any prescription is safe,
                appropriate and as effective as possible.
              </p>
            </>
        )
    }, {
        id: 2,
        question: 'How much does a visit and medication cost?',
        answer: () => (
            <>
              <p>We charge:</p>
              <ol>
                  <li>One-time provider visit fee of $15.</li>
                  <li>Subscription of Sildenafil is $18 for 4 uses, $27 for 6 uses, $36 for 8 uses or $45 for 10 uses.</li>
                  <li>You can get your shipment monthly or quarterly. Quarterly shipments get a discount relative to prices above.</li>
                  <li>Standard shipping is free of charge (7-10 days, often sooner); Express shipping (2 days) for $20 and Next day shipping for $40</li>
              </ol>
            </>
        )
    }, {
        id: 3,
        question: 'Can I use insurance to pay for my visit?',
        answer: () => (
            <p>This service is off benefit and not covered by your insurance</p>
        )
    }, {
        id: 4,
        question: 'What are my payment options?',
        answer: () => (
            <p>You can pay using VISA, MasterCard, American Express, and Discover. You cannot pay by check, money orders or cash. </p>
        )
    }, {
        id: 5,
        question: 'Why do you need a picture of my ID and separate picture?',
        answer: () => (
            <>
              <p>We require two photos: a photo of a government-issued ID and a clear photo of your face. We use these to confirm your identity before we can treat you via telemedicine.
                  The two pictures must match each other. </p>
              <p>The photo of your ID must show:</p>
              <ul>
                  <li>Your photo</li>
                  <li>Your full legal name</li>
                  <li>Date of birth</li>
                  <li>Expiration date (must be currently valid)</li>
              </ul>
              <p>The photo of your face must not:</p>
              <ul>
                  <li>Be a photo of another photo</li>
                  <li>Include you wearing sunglasses, hats or any facial obstruction</li>
                  <li>Have a filter applied</li>
                  <li>Be a family photo</li>
              </ul>
            </>
        )
    }, {
        id: 6,
        question: 'Is my information safe?',
        answer: () => (
            <>
              <p>
                Your privacy and the security of your data and information is of the highest importance to us.
                That’s why we have put the strictest of protocols in place to ensure total security for your personal information and data.
                We have also ensured our platform meets or exceeds all of the relevant relevant privacy laws
              </p>
              <p>For further details, please refer to our <a href='https://humana.truepill.com/privacy-policy'>privacy policy</a> and our <a href='https://humana.truepill.com/privacyNotes'>notice of privacy practice</a>.</p>
            </>
        )
    }, {
        id: 7,
        question: 'When can I expect a response from my medical provider?',
        answer: () => (
            <p>
                Generally, the healthcare providers will approve or reject your prescription request within 24 hours, but often sooner.
                If you have any questions, please email us <a href='mailto:humana@truepill.com'>humana@truepill.com</a> or call us at{' '}
                <a href='tel:+1-855-645-8114'>+1-855-645-8114</a>
            </p>
        )
    }, {
        id: 8,
        question: 'I have a question for my healthcare provider',
        answer: () => (
            <p>If you have any questions and want to reach out email us <a href='mailto:humana@truepill.com'>humana@truepill.com</a>{' '}
            or call us at <a href='tel: +1-855-645-8114'>+1-855-645-8114</a></p>
        )
    }]
}, {
    id: 'erectile-dysfunction',
    content: [{
        id: 1,
        question: 'What causes erectile dysfunction?',
        answer: () => (
            <>
              <p>Many factors can cause erectile dysfunction including:</p>
              <ol>
                  <li>Medical conditions and ailments such as diabetes, high cholesterol and high blood pressure.</li>
                  <li>Being aged over 50 although it’s not uncommon for men to suffer from erectile dysfunction in their 40s, 30s and even 20s.</li>
                  <li>Obesity is a risk factor for erectile dysfunction, as with many other medical conditions. It is advisable to maintain a healthy weight through diet and lifestyle choices.</li>
                  <li>
                    Medication prescribed to lower blood pressure and beta blockers can lower blood pressure in the penis.
                    This can make it difficult to get and maintain an erection. Always inform your doctor of all medication you currently take –
                    this includes completing an online consultation.
                  </li>
              </ol>
            </>
        )
    }, {
        id: 2,
        question: 'How does erectile dysfunction treatment work through Humana?',
        answer: () => (
            <>
              <p>Thanks to digital technology, treating erectile dysfunction is now discreet, convenient and affordable. It involves just a few easy steps:</p>
              <ol>
                  <li>Complete our questionnaire</li>
                  <li>Upload your photo ID</li>
                  <li>Add your recent blood pressure reading</li>
              </ol>
              <p>One of our US licensed physicians will then assess your answers and determine whether you are a good candidate for erectile dysfunction treatment.</p>
            </>
        )
    }, {
        id: 3,
        question: 'Is this a safe way to receive treatment?',
        answer: () => (
            <>
              <p>
                Telemedicine relies on patients giving full and truthful answers to the best of their knowledge.
                As long as this is the case, telemedicine is a safe way to receive treatment. So treat a telemedicine consultation exactly as you would if
                you were speaking to a doctor or nurse face to face. This ensures we can accurately assess your medical and personal history -
                and we can consider a safe and effective treatment that has the best possible chance of working for you.
              </p>
              <p>For the same reason, it’s important to carefully follow all instructions when you upload photos of yourself and your ID.</p>
            </>
        )
    }, {
        id: 4,
        question: 'What oral medications are available to treat erectile dysfunction?',
        answer: () => (
            <p>We prescribe sildenafil to treat erectile dysfunction. You may have heard the name Viagra before - that’s a branded version of sildenafil.</p>
        )
    }, {
        id: 5,
        question: 'How effective are medications used to treat erectile dysfunction?',
        answer: () => (
            <p>These medications can be very effective for many patients. <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/20895s039s042lbl.pdf">Around 82% of men</a> will see some benefit from using erectile dysfunction treatments. Men who suffer mild-to-moderate erectile dysfunction often experience great benefits.</p>
        )
    }, {
        id: 6,
        question: 'How do erectile dysfunction medications work?',
        answer: () => (
            <p>
                Erections happen when the brain is aroused. It sends signals to fill the penis with blood, causing an erection.
                Medications like sildenafil temporarily make it easier for blood to fill the penis, making erections easier.
                This happens because sildenafil reduces a chemical called PDE5 in the body; men who suffer from erectile dysfunction have excess PDE5,
                which makes erections difficult to get.
            </p>
        )
    }, {
        id: 7,
        question: 'How long does Sildenafil last?',
        answer: () => (
            <>
              <p>Sildenafil can take around half an hour to work - up to around an hour. The effects of sildenafil usually last approximately two to three hours. However, the effects can vary from one person to the next - some people find the effects can last for four to six hours.</p>
              <p>If you notice the effects beginning to wear off, it’s important NOT to take another dose until 24 hours after your last one - even if the effects begin wearing off sooner than you would like.</p>
            </>
        )
    }, {
        id: 8,
        question: 'What are Sildenafil’s side effects?',
        answer: () => (
            <p>Side effects are unusual when taking sildenafil - and when they do occur, it’s likely they will be mild. However, reported side effects include hot flush or facial flushing, feelings of nausea, blurred vision, dizziness and indigestion.</p>
        )
    }, {
        id: 9,
        question: 'Who can’t take Sildenafil?',
        answer: () => (
            <>
              <p>Only men aged 18+ who have erectile dysfunction can take sildenafil. It has no benefits if you do not suffer from erectile dysfunction and will not enhance sexual performance or help with other conditions such as premature ejaculation.</p>
              <p>If you have erectile dysfunction symptoms and are under 18, speak to a doctor or pharmacist.</p>
              <p>Sildenafil has not been tested on women - its effects on their health are unknown.</p>
            </>
        )
    }, {
        id: 10,
        question: 'Why do I need to supply a blood pressure reading to be prescribed erectile dysfunction medication?',
        answer: () => (
            <p>If your blood pressure is above or below a certain value, taking sildenafil can have serious adverse effects. That’s why we insist on a recent blood pressure reading - taken within the last six months. So please do ensure you have a recent reading before starting your online consultation.</p>
        )
    }]
}, {
    id: 'order',
    content: [{
        id: 1,
        question: 'How can I return my medications?',
        answer: () => (
            <p>Unfortunately we cannot accept returns of prescribed medication. However, if you feel an error has been made email
                us at <a href='mailto: humana@truepill.com'>humana@truepill.com</a> or call us at <a href='tel: +1-855-645-8114'>+1-855-645-8114</a>.</p>
        )
    }, {
        id: 2,
        question: 'How do I cancel my order or subscription?',
        answer: () => (
            <>
              <p>You may cancel a subscription at any time up to forty-eight (48) hours before the applicable monthly processing date of your subscription.</p>
              <p>You can cancel or edit your first order until our warehouse begins to prepare your shipment. Unfortunately, after our warehouse begins preparing your shipment, your order can no longer be canceled or edited.</p>
              <p>To cancel your order or subscription, email us at <a href='mailto: humana@truepill.com'>humana@truepill.com</a> or call us at <a href='tel: +1-855-645-8114'>+1-855-645-8114</a>. Ensure to provide us with your email address, first name, last name, date of birth and shipping address.</p>
            </>
        )
    }]
}, {
    id: 'shipping',
    content: [{
        id: 1,
        question: 'How long does it take to deliver my prescription?',
        answer: () => (
            <p>Standard shipping is free of charge (7-10 days, often sooner); Express shipping (2 days) for $20 and Next day shipping for $40.</p>
        )
    }, {
        id: 2,
        question: 'How does my order ship?',
        answer: () => (
            <p>Order ships in discrete polybubble packaging with all medications in plastic vials that are perfect for your medicine cabinet or nightstand.</p>
        )
    }]
}]
