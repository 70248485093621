import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { ColorPrimaryDarkGreen } from '../../styleVariables'
import { categories, questions } from './questions'
import { Details } from '../styles/common'

import Caret from '../../assets/icons/caret.svg'

const FAQs = () => {
    const [selectedCategory, selectCategory] = useState('telemedicine')

    return (
        <Container>
            <h1>FAQs</h1>
            <TopicColumn>
              <ul>
              { categories.map(({id, name}) => (
                <li>
                    <DefaultButton 
                      type='button' 
                      key={id} 
                      onClick={() => selectCategory(id)}
                      active={selectedCategory === id}
                    >
                        {name}
                    </DefaultButton>
                </li>
                ))}
              </ul>
            </TopicColumn>
            <QuestionsColumn>
                {questions.map(({id, content}) => (
                    id === selectedCategory && (
                      content.map(({id, question, answer: Answer}) => (
                        <Details key={id}>
                            <SummaryBlock>
                                {question}
                                <Caret />
                            </SummaryBlock>
                            <Answer />
                        </Details>
                      ))
                    )
                ))}
            </QuestionsColumn>
        </Container>
    )
}

const Container = styled.section`
  margin-bottom: 5rem;
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: repeat(12,1fr);
  
  h1 {
    width: 100%;
    text-align: center;
    font-size: 2.25rem;
    color: ${ColorPrimaryDarkGreen};
    grid-row: 1;
    grid-column: 6/8;
  }

  @media(min-width: 769px) {
    h1 {
      margin-bottom: 3rem;
    }
  }
`

const TopicColumn = styled.div `
    grid-column: 2/12;
    grid-row: 2;
    max-width: 100%;
    overflow: hidden;

    ul {
      margin-top: 0;
      display: flex;
      overflow-y: scroll;
      text-align: center;
      list-style-type: none;
      padding-left: 0;
    }

    li {
      display: inline-block;
      padding: 1rem 0;
    }

    @media(min-width: 769px) {
      grid-column: 2/5;
      max-width: 450px;

      ul {
        display: block;
        overflow-y: hidden;
        text-align: left;
      }
      
      li:first-child {
        padding-top: 0;
      }

      li {
        display: block;
      }
    }
`

const DefaultButton = styled.button `
  width: max-content;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 500;
  color: ${({active}) => active ? `${ColorPrimaryDarkGreen}` : 'rgba(107,108,111,1)'};
  cursor: pointer;
  text-align: left;

  @media(min-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
  }
`

const QuestionsColumn = styled.article`
  grid-column: 2/12;
  grid-row: 3;

  @media(min-width: 769px) {
    grid-column: 5/12;
    grid-row: 2;
  }
`

const SummaryBlock = styled.summary`
  outline: none;
`



export default FAQs
