import React from 'react'
import styled from 'styled-components/macro'

import Layout from '../components/layout'
import FAQs from '../components/FAQs/index'

const FAQ = () => (
    <Layout hideSignUpButton={true}>
      <FAQs />
    </Layout>
)


export default FAQ